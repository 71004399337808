.flex-container {
    /* We first create a flex layout context */
    display: flex;
    
    /* Then we define the flow direction 
       and if we allow the items to wrap 
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
    flex-flow: row wrap;
    
    /* Then we define how is distributed the remaining space */
    justify-content: space-around;
    
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .testimonialStyle {
    padding: 5px;
    width: 200px;
    height: 150px;
    margin-top: 10px;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
    color: black;
    content: middle;
  }

  
  .carousel-root {
    width: 64% !important;
    margin: auto !important;
    margin-top: 3% !important;
  }
  
  .carousel .slide {
    background: #fff !important;
    color: black;
    height: 100%;
  }
  
  .carousel .slide img {
    width: 139px !important;
    border-radius: 50%;
  }

  

  .myCarousel {
    background: black;
    margin-top: -6%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    border: 10px solid #ddd;
  }
 

